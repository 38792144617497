



const GoogleFormViewer = () => {
    const formUrl = 'https://docs.google.com/forms/d/1wn93kiivCNHqU_dg8y7h6rrvXrEPMYjqomUeEl9oRC8/viewform?embedded=true';
  
    return (
      <div style={{ width: '100%', height: '80vh', overflow: 'hidden' }}>
        <iframe
          src={formUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Google Form"
          style={{ border: 'none' }}
        >
          Loading…
        </iframe>
      </div>
    );
  };
  export default GoogleFormViewer;
  