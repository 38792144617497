import React from 'react'
// import NoPrediction from './NoPrediction';
import { motion } from 'framer-motion';
import { formatToPercentage } from '../../Utils/utils';
import { NoPrediction } from './NoPrediction';

const HalftimeFulltime: React.FC<any> = ({ game }) => {
    const prediction = (game.prediction !== null && game.prediction?.halftime_fulltime !== '') ? JSON.parse(game?.prediction?.halftime_fulltime) : null;

    return (
        <>
            {prediction !== null  ? (<div className='flex flex-row gap-5 prediction-component'>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg w-[17rem] bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <img src={game?.homeLogo} alt="Icon" className="w-6 h-6 mt-2 ml-3 mb-3" />
                        <div className="mt-2 ml-3 mb-1 w-[15rem] flex items-center justify-between">
                            <div className='flex items-center'>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200] mr-3">HT</h1>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">FT</h1>
                            </div>

                            <h1 className="text-[0.6rem] md:text-[0.8rem] text-[#838383] font-[200]">Possibility</h1>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Home</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Home</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['H/H'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Draw</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Home</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['H/D'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1 mb-2'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Away</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Home</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['H/A'])}%</h1>
                            </div>
                        </div>
                    </div>

                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg w-[17rem] bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className='flex items-center space-x-6'>
                            <div className='mb-3 flex mt-2 ml-3'>
                                <img src={game?.homeLogo} alt="Icon" className="w-6 h-6" />
                                <img src={game?.awayLogo} alt="Icon" className="w-6 h-6" />
                            </div>
                            <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#D9D9D9]">Draw</h1>
                        </div>
                        <div className="mt-2 ml-3 mb-1 w-[15rem] flex items-center justify-between">
                            <div className='flex items-center'>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200] mr-3">HT</h1>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">FT</h1>
                            </div>

                            <h1 className="text-[0.6rem] md:text-[0.8rem] text-[#838383] font-[200]">Possibility</h1>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Away</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Draw</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['D/A'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Draw</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Draw</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['D/D'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1 mb-2'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Home</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Draw</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['D/H'])}%</h1>
                            </div>
                        </div>
                    </div>

                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg w-[17rem] bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <img src={game?.awayLogo} alt="Icon" className="w-6 h-6 mt-2 ml-3 mb-3" />
                        <div className="mt-2 ml-3 mb-1 w-[15rem] flex items-center justify-between">
                            <div className='flex items-center'>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200] mr-3">HT</h1>
                                <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">FT</h1>
                            </div>

                            <h1 className="text-[0.6rem] md:text-[0.8rem] text-[#838383] font-[200]">Possibility</h1>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Away</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Away</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['A/A'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Draw</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Away</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['A/D'])}%</h1>
                            </div>
                        </div>

                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg m-1 mb-2'>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] flex items-center justify-between">
                                <div className='flex items-center space-x-2'>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#838383] font-[200]">Home</h1>
                                    <span className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#838383]">/</span>
                                    <h1 className="text-[0.8rem] md:text-[1rem] text-[#00FF87] font-[200]">Away</h1>
                                </div>

                                <h1 className="text-[0.8rem] md:text-[1rem] font-extrabold font-[Orbitron] text-[#F4900C]">{formatToPercentage(prediction['A/H'])}%</h1>
                            </div>
                        </div>
                    </div>


                </motion.div>
            </div>)
        : (
            <NoPrediction />
        )    
        }
        </>
    )
}

export default HalftimeFulltime;