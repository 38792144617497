import React, { useState, Fragment } from 'react'
import LogoutImage from '../../asset/logout-circle.png';
import PasswordImage from '../../asset/password-filled.png';
import AirdropImage from '../../asset/airdop_icon.png';
import WalletImage from '../../asset/wallet.png';
import WalletLock from '../../asset/wallet_lock.png';
import GuideImage from '../../asset/icons/cat-icon.png';
import WalkThroughImage from '../../asset/walkthrough.png';
import DocumentationImage from '../../asset/documentation.png';





//import ChangePasswordModal from './ChangePassword';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../Redux/userRedux';
import ChangePasswordModal from './ChangePassword';
//import { logout } from '../../Redux/userRedux';
//import { useDispatch } from 'react-redux';

const AccountModal: React.FC<any> = ({ isOpenAccount, closeAccountModal }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGuideOpen, setIsGuideOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const router = useNavigate();
    const dispatch = useDispatch();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleGuide = () => {
        setIsGuideOpen(!isGuideOpen);
    };

    const Logout = () => {
        router('/');
        dispatch(logout());
        localStorage.clear();
        //return <Navigate to="/" replace={true} />
    }

    return (
        <>
            {/* 
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className={`relative z-10`} onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-10" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-50 bottom-[100px] absolute max-w-md transform overflow-hidden rounded-xl left-10 bg-[#0B0B0B] p-4 text-left align-middle shadow-xl transition-all">

                                    <div className="mt-2">
                                        <div className="flex items-center justify-between my-4 cursor-pointer" onClick={openModal}>
                                            <p className="text-[#545454] font-medium">Change Password</p>
                                            <img src={PasswordImage} alt="Logout Icon" className="w-6 h-6 ml-8" />

                                        </div>
                                        <div className="flex items-center justify-between my-4 cursor-pointer">
                                            <p className="text-[#545454] font-medium">Logout</p>
                                            <img src={LogoutImage} alt="Logout Icon" className="w-6 h-6 ml-8" />

                                        </div>
                                    </div>

                                    
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition> */}



            {/* <div className="fixed bottom-[100px] left-10 bg-[#0B0B0B] p-4 border-radius shadow-md">
            
                <div className="flex items-center justify-between my-4 cursor-pointer" onClick={openModal}>
                    <p className="text-[#545454] font-medium">Change Password</p>
                    <img src={PasswordImage} alt="Logout Icon" className="w-6 h-6 ml-8" />

                </div>
                <div onClick={Logout} className="flex items-center justify-between my-4 cursor-pointer">
                    <p className="text-[#545454] font-medium">Logout</p>
                    <img src={LogoutImage} alt="Logout Icon" className="w-6 h-6 ml-8" />

                </div>
            </div>

            <ChangePasswordModal openModal={openModal} closeModal={closeModal} isModalOpen={isModalOpen} /> */}

            {isOpenAccount && (
                <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">
                    <div onClick={closeAccountModal} className="modal-overlay absolute w-full h-full bg-red opacity-5"></div>

                    <div className="modal-container top-[90px] right-20 md:right-5 w-[72%] md:w-[20%] absolute bg-[#2A2A2A] mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal bg-black bg-opacity-50 glass-card border-[1px] border-[#2A2A2A]" 
                    >

                        <div className="modal-content py-2 text-left px-4">
                            <div onClick={() => {
                                setSelectedItem('airdrop')
                                router('/airdrop');
                            }} className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A]  p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                <img src={AirdropImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                <p className={` hover:text-[#D9D9D9] font-medium text-[0.8rem] md:text-[1rem] ${selectedItem === 'airdrop' ? 'text-[#00FF87]' : 'text-[#545454]'}`}>Airdrop</p>
                            </div>

                            <div className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius" onClick={() => {
                                openModal()
                                setSelectedItem('cp')
                            }}>
                                <img src={PasswordImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6 " />
                                <p className={`hover:text-[#D9D9D9] font-medium text-[0.8rem] md:text-[1rem] ${selectedItem === 'cp' ? 'text-[#00FF87]' : 'text-[#545454]'}`}>Change Password</p>
                            </div>

                            <div onClick={() => {
                                setSelectedItem('cw')
                            }} className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                <img src={WalletImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6 " />
                                <p className={`hover:text-[#D9D9D9] font-medium text-[0.8rem] md:text-[1rem] ${selectedItem === 'cw' ? 'text-[#00FF87]' : 'text-[#545454]'}`}>Connect Wallet</p>
                                <img src={WalletLock} alt="Logout Icon" className="w-4 h-4 " />
                            </div>

                            <div onClick={() => {
                                toggleGuide();
                                setSelectedItem('guide');
                            }} className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                <img src={GuideImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                <p className={`hover:text-[#D9D9D9] font-medium text-[0.8rem] md:text-[1rem] ${selectedItem === 'guide' ? 'text-[#00FF87]' : 'text-[#545454]'}`}>Guide</p>
                            </div>

                            {isGuideOpen && <div className='bg-[#080808] py-2 text-left px-4 border-radius'>
                                <div className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                    <img src={DocumentationImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                    <p className="hover:text-[#D9D9D9] text-[#545454] font-medium text-[0.8rem] md:text-[1rem]">Documentation</p>
                                </div>

                                <div className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                    <img src={WalkThroughImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                    <p className="hover:text-[#D9D9D9] text-[#545454] font-medium text-[0.8rem] md:text-[1rem]">Walk through</p>
                                </div>
                            </div>}

                            <div onClick={Logout} className="flex items-center justify-start space-x-4 mb-2 cursor-pointer hover:bg-[#0A0A0A] p-3 md:p-6 hover:border-[1px] hover:border-[#2A2A2A] border-radius">
                                <img src={LogoutImage} alt="Logout Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                <p className="hover:text-[#D9D9D9] hover:text-[#D9D9D9] text-[#545454] font-medium text-[0.8rem] md:text-[1rem]">Logout</p>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <ChangePasswordModal openModal={openModal} closeModal={closeModal} isModalOpen={isModalOpen} />
        </>
    )
}

export default AccountModal;
