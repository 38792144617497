import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useEffect, useState } from 'react';
// import { FetchCarousels } from '../../../Redux/apiCalls';
// import Spinner from '../../../Utils/Spinner';
// import Loader1 from '../../../Utils/Loader1';
import Win from '../asset/win.png';
import Win1 from '../asset/win1.png'
// import Loader from '../../../Utils/Loader/Loader';
import { motion } from 'framer-motion';

const data = [
  {
    id: 1,
    fileName: 'slider 1',
    filePath: Win
  },
  {
    id: 2,
    fileName: 'slider 2',
    filePath: Win1
  }
]
const Slider = () => {
  const [carousels, setCarousels] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const GetCarousels = async () => {
    try {
      setIsLoading(true);
      setCarousels(data)
      setIsLoading(false);
      //   const _Tmp = await FetchCarousels();
      //   if (_Tmp.carousel.length > 0) {
      //     setIsLoading(false);
      //     setCarousels(_Tmp.carousel);
      //   }
      //   else {
      //     setIsLoading(false);
      //     return
      //   }
    } catch (error) {
      setIsLoading(false);
    }

  }

  useEffect(() => {
    GetCarousels();
  }, []);


  return (
    <>


      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}

      >
        <div className=' rounded-lg overflow-hidden h-[160px] md:h-[250px] '>

          {
            !isLoading && carousels?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <div className="bg-[#0B0B0B] border-radius w-full md:w-[50%] p-[40px] md:p-[40px]">
                  <p className='text-[#838383]'>Carousels are not available</p>
                </div>
              </div>
            ) : (
              !isLoading && <Splide options={
                {
                  perPage: 1,
                  focus: 0,
                  lazyLoad: 'nearby',
                  pagination: false,
                  arrows: false,
                  gap: '1rem',
                  breakpoints: {
                    640: {
                      perPage: 1,
                      gap: '0.1rem'
                    },
                  },
                  autoplay: true,
                  type: 'loop',

                }
              } aria-label="My Slides">
                {
                  carousels?.map((item: any, i: number) => (
                    <SplideSlide className='rounded-lg cursor-pointer' key={i}>
                      {/* {item?.filePath} */}
                      <img src={item?.filePath} alt={item?.filename} className="object-fit w-full md:w-32 lg:w-full  p-2 h-[160px] md:h-[250px]  rounded-lg" style={{ borderRadius: '25px', boxShadow: '4px 4px 0 0 #000000' }} />
                    </SplideSlide>
                  ))
                }
              </Splide>
            )
          }

          {
            isLoading &&
            <div className="flex justify-center items-center mt-6">
              {/* <Loader /> */}
            </div>

          }

        </div>
      </motion.div>
    </>
  );
};

export default Slider;
