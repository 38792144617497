import React from 'react'

export const NoPrediction = () => {
    return (
        <div className="flex justify-center items-center h-full pb-4">
            <div className="bg-[#F44336] text-[#ffffff] font-[200] py-2 px-4 shadow-md border-radius w-full md:w-[50%]">
                No prediction available yet
            </div>
        </div>
    )
}
