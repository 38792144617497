import React, { useState } from 'react'
import Tether from '../asset/tether.png';
import EqualIcon from '../asset/equal-box.png';
import RaxIcon from '../asset/rax.png';
import SolarCard from '../asset/solar_card-bold.png';
import { ToastContainer, toast } from 'react-toastify';

const modeOfPayment = [
    { value: 0, title: 'Card', image: SolarCard },
    { value: 0.004, title: 'USDT', image: Tether },
    { value: 100, title: 'RAX', image: RaxIcon },
]

export const BuyPredictionPoint = () => {
    const [activeTab, setActiveTab] = useState(-1);
    const [isPaymentValue, setPaymentValue] = useState(0);
    const [typeOfPayment, setTypeOfPayment] = useState(null);
    const [amount, setAmount] = useState<any>(0);
    const [isWalletConnected, setWalletConnected] = useState(false);


    const handleTabClick = (index: number, value: any, mode: any) => {
        let totalAmountToPay = 0;

        setActiveTab(index);
        setTypeOfPayment(mode);

        if (mode === 'Card') {
            totalAmountToPay = amount;
            setPaymentValue(totalAmountToPay);
        }

        if (mode === 'USDT') {
            totalAmountToPay = 0.004 * amount;
            setPaymentValue(totalAmountToPay);
        }

        if (mode === 'RAX') {
            totalAmountToPay = 100 * amount;
            setPaymentValue(totalAmountToPay);
        }


    };

    const handlePayment = () => {

        if (amount === 0) {
            toast.warn("Please enter a valid amount")
        }
        else if (amount === '') {
            toast.warn("Please enter a valid amount")
        }
        else if (amount === null) {
            toast.warn("Please enter a valid amount")
        }
        else {

        }
    }

    const handleConnectWallet = () => {
        setWalletConnected(!isWalletConnected);
    }
    return (
        <div className="mt-4 ">

            <div className="mt-0 p-5 border-radius rounded-lg bg-[#0B0B0B] border-[1px] border-[#2A2A2A]" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
                <div className="text-left">
                    <span className='text-[#373737] text-[0.7rem]'>Min: 10 points</span>
                </div>
                <div className="flex items-center justify-between space-x-4">
                    <input
                        type="number"
                        className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-[1px] border-[#2A2A2A] border-radius placeholder-[#545454]"
                        placeholder='Enter amount'
                        style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                        value={amount}
                        onChange={(e: any) => setAmount(e.target.value)}
                    />

                    <button
                        onClick={handlePayment}
                        className={` ${isWalletConnected ? 'bg-[#00FF87] text-[#000000]' : 'bg-[#373737] text-[#545454]'}  flex justify-center items-center  font-[Roboto Mono] font-[900] text-[0.9rem] py-3 px-4 w-full border-radius md:w-[200px]`}
                        disabled={!isWalletConnected}
                    >
                        Buy points
                    </button>

                </div>

                { isWalletConnected && <div className=" flex items-center my-3">
                    <div className="w-6 h-6 mr-4">
                        <img src={EqualIcon} alt="modal" className="w-full h-auto" />
                    </div>
                    <div className="">
                        <div className="text-xl font-normal text-[#00FF87]">
                            {typeOfPayment === 'Card' && <span className="-top-2 -right-2 text-base mr-2">$</span>}
                            {isPaymentValue}
                            <span className="-top-2 -right-2 text-base ml-2">{typeOfPayment === 'USDT' ? 'BTC' : typeOfPayment === 'RAX' ? 'RAX' : ''}</span>
                        </div>
                    </div>
                </div>}

                { isWalletConnected && <div className="">
                    <span className="text-[0.7rem] font-[400] text-[#373737]">Select Method Of Payment</span>
                </div>}

                {isWalletConnected && <div className="mt-3">
                    <ul className="flex flex-wrap justify-around text-sm font-medium text-center">
                        {modeOfPayment?.map((tab: any, index: number) => (
                            <li key={index} className="mr-1 mb-4">
                                <button
                                    className={`inline-block px-4 py-3 ${activeTab === index
                                        ? 'text-[#000000] bg-[#FF9800] font-[900]'
                                        : 'text-[#D9D9D9] bg-[#151515] font-normal'
                                        } w-[100px] md:w-[100px] flex items-center border-radius`}
                                    onClick={() => handleTabClick(index, tab.value, tab.title)}
                                    style={{ boxShadow: `${activeTab === index ? '4px 4px 0 0 #000000' : ''}` }}
                                >
                                    <div className="w-6 h-6 mr-4">
                                        <img src={tab.image} alt="modal" className="w-full h-auto" />
                                    </div>
                                    {tab.title}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>}

                <div className="w-full mt-3">
                    <button onClick={handleConnectWallet} className="bg-[#00FF87] text-[#000000] px-2 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold md:w-[200px]">
                        <span className="font-[900] text-[0.9rem] ">Connect wallet</span>
                    </button>
                </div>

            </div>


        </div>
    )
}
