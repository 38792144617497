import React from 'react'
import { AirDropBonusInfo } from './AirDropBonusInfo'
import { TotalReferrals } from './TotalReferrals'
import { motion } from 'framer-motion';
import Exclude from '../asset/Logo12.png';
import { useNavigate } from 'react-router-dom';

export const LeftSideBar = () => {
    const router = useNavigate();

    return (
        <aside className="text-white h-full z-10 w-1/5 hidden lg:block p-3 fixed top-0 left-0 h-full overflow-auto side-bar ml-10">
            <nav>
                <div className="flex items-center justify-start mb-10">

                    <span onClick={() => {
                        router('/');
                    }}  className='flex items-center'>
                        <img src={Exclude} alt="BLogo" className="w-50 h-16" />

                    </span>
                </div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                    className="mb-5"
                >
                    <AirDropBonusInfo />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 2.5 }}

                >
                    <TotalReferrals />
                </motion.div>


            </nav>
        </aside>
    )
}
