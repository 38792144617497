import React, { useState } from 'react';
import AccountAdd from '../../../asset/account-add.png';
import PasswordFilled from '../../../asset/password-filled.png';
import { useDispatch, useSelector } from 'react-redux';
import { SendVerificationEmail, login } from '../../../Redux/apiCalls';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from 'react-router-dom';
import Loader from '../../../Utils/Loader/Loader';
import EyeOpen from '../../../asset/eye.svg';
import EyeSlash from '../../../asset/eye-off.svg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Utils } from '../../../Utils/utils';

const MySwal = withReactContent(Swal)


const SignInFeature: React.FC<any> = ({ setActiveComponent }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const { isFetching, error } = useSelector((state: any) => state.user);
    const user = useSelector((state: any) => state.user.currentUser);
    const errorCode = useSelector((state: any) => state.user);


    const HandleLogin = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);


        if (email === '' || password === '') {
            toast.warn("All fields are required.");
            setIsLoading(false);
        }
        else {

            try {
                const _IAuth = await login(dispatch, email, password);


                if (user.message === 'Successful') {
                    setIsLoggedIn(true);
                    toast.success("Login successful");
                    setIsLoading(false);

                }
                else {

                    if (Utils.errorStatusCode === 400) {
                        toast.warn("Empty field is not allowed");
                        setIsLoading(false);
                    }
                    else if (Utils.errorStatusCode === 403) {
                        toast.warn("Your account is yet to be verified. Please check your email for verification code.");
                        
                        const verificationInfo = await SendVerificationEmail(email)
                        if (verificationInfo.message === 'Verification email sent') {
                            setIsLoading(false);
                            MySwal.fire({
                                title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Email sent</p>,
                                text: "Verification email sent. Please check your email",
                                icon: "success"
                            });
                        }
                        else {
                            setIsLoading(false);
                            toast.warn("An error has occurred. please try again")
                        }

                    }
                    else if (Utils.errorStatusCode === 404) {
                        toast.warn("Email/Password is incorrect");
                        //setPassword('');
                        setIsLoading(false);
                    }
                    else if (Utils.errorStatusCode === 502) {
                        toast.warn("Network error");
                        //setPassword('');
                        setIsLoading(false);
                    }
                    else {
                        toast.warn("Try again");
                        //setPassword('');
                        setIsLoading(false);
                    }
                    // console.log(errorCode)
                    // console.log(Utils.errorStatusCode)

                    // toast.warn("Invalid login details");
                    // setIsLoading(false);

                }
            } catch (error: any) {
                console.log(error)
                toast.warn(error.message);
                setIsLoading(false);
            }
        }

    }

    // const renderLoadingModal = () => {
    //     if (isLoading) {
    //         MySwal.fire({
    //             title: <p className='text-[0.8rem] md:text-[1rem] font-regular text-[#000000]'>Please wait...</p>,
    //             allowOutsideClick: false,
    //             didOpen: () => {
    //                 Swal.showLoading();
    //             },
    //         });
    //     }
    //     else {
    //         Swal.close()
    //     }
    // };

    // renderLoadingModal();

    if (isLoggedIn === false) {
        return (

            <div className="flex justify-center items-center min-h-screen p-2 md:p-0">
                <ToastContainer autoClose={5000} />

                {isLoading && <Loader />}
                {/* {isLoading && 
                 <>
                 {MySwal.fire({
                    title: <p className='text-[0.8rem] md:text-[1rem] font-regular text-[#000000]'>Please wait...</p>,
                    didOpen: () => {
                      // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                      MySwal.showLoading()
                    },
                    allowOutsideClick: false,
                  })}
                 </>
                
                } */}
                <div className=" p-8 border-radius shadow-lg w-full md:w-96 glass-card">
                    <h1 className="text-[1.2rem] md:text-2xl font-semibold mb-2 md:mb-4 text-[#8D8D8D] py-4 md:py-8 font-medium">Welcome back</h1>
                    <form>
                        <div className="mb-2 md:mb-4">

                            <input
                                type="text"
                                className="w-full h-12 px-4 py-2 border-radius text-[0.7rem] md:text-[1rem] focus:outline-none text-[#8D8D8D] placeholder-[#545454] border"
                                placeholder='Email'
                                style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </div>
                        <div className="mb-4 relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="w-full h-12 px-4 py-2 focus:outline-none text-[0.7rem] md:text-[1rem] text-[#8D8D8D] border-radius placeholder-[#545454] border"
                                placeholder='Password'
                                style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                className="absolute inset-y-0 right-0 px-3 py-2"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ?
                                    <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                    :
                                    <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                }
                            </button>
                        </div>
                        <div className="my-8 md:my-16">
                            <button
                                onClick={HandleLogin}
                                type="submit"
                                className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[0.8rem] md:text-[1rem] py-4 px-4 w-full rounded"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Log In'}

                            </button>
                        </div>


                        <div className="flex items-center justify-between mt-8">
                            {/* Left-hand side */}
                            <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('forgotPassword')}>
                                <img src={PasswordFilled} alt="Left Image" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                                <p className="text-[#8D8D8D] text-[0.8rem] md:text-sm font-medium">Forgot Password</p>
                            </div>

                            {/* Right-hand side */}
                            <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('signUp')}>
                                <img src={AccountAdd} alt="Right Image" className="w-4 h-4 md:w-6 md:h-6" />
                                <p className="text-[#8D8D8D] text-[0.8rem] md:text-sm font-medium ml-2">Sign Up</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    else {
        return <Navigate to='/loading-data' />
    }

};

export default SignInFeature;
