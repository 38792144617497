import React from 'react'
import CloseIcon from '../../../asset/close.png';
import Exclude from '../../../asset/Logo12.png';
import { AirDropCountDown } from './AirDropCountDown';
import { AirDropTopUsers } from './AirDropTopUsers';

export const AirDropMenu: React.FC<any> = ({ toggleSidebar }) => {
    return (
        <div className='bg-[#151515] h-full'>

            <div className="flex justify-between items-center  p-3 md:hidden">
                <div className="flex items-center justify-start">
                    <a href="#" className='flex items-center'>
                        <img src={Exclude} alt="BLogo" className="w-40 h-16" />
                    </a>
                </div>

                <div className='' onClick={toggleSidebar}>
                    <img src={CloseIcon} alt="BLogo" className="w-12 h-12" />
                </div>
            </div>

            <div className='p-3'>
                <AirDropCountDown />

                <AirDropTopUsers />
            </div>
        </div>
    )
}
