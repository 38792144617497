import React from 'react'
import Stadium from '../asset/stadium.png';
import Stadium1 from '../asset/icons/backdrop.png';
import SolidTime from '../asset/solid_time.png';
// import Left from '../asset/Group.png';
// import DownFill from '../asset/left-fill.png';
import EPL from '../asset/EPL.png';
import Soccer from '../asset/soccer.png';
import Slash from '../asset/slash.png';
import Markets from './Markets';
import { useSelector } from 'react-redux';
import { FormatDate } from '../Utils/utils';
import RobotImage from '../asset/prediction.png';

{/* <div className="absolute inset-0 bg-black opacity-25"></div> */}
{/* <img
                                src={Slash}
                                alt="slash"
                                className="w-4 h-4 md:w-4 md:h-8 rounded-full object-cover"
                            /> */}
{/* <h5 className='font-[200] text-[0.9rem] md:text-[1rem] text-[#838383]'>Predictions</h5>
                        <h5 className="text-[#00FF87] font-extrabold font-[Oxta]">60K</h5> */}

export const GameCard: React.FC<any> = ({ game }) => {
    const marketState = useSelector((state: any) => state.market.markets);

    return (
        <div className="relative mt-0 bg-[#0B0B0B] bg-cover bg-center bg-no-repeat p-5 border-radius rounded-lg border-[1px] border-[#2A2A2A] mb-5" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A', backgroundImage: `url(${Stadium1})` }}>
            
            <div className="md:flex items-center justify-start md:space-x-10 md:mb-10">
                <div className='flex md:flex-col flex-row space-x-2 md:space-x-0 mb-4 md:mb-0'>
                    <div className='flex items-center md:mb-1'>
                        <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                            <img src={Soccer} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-1 md:ml-2">Soccer</h2>
                    </div>

                    <div className='flex items-center md:mb-1'>
                        <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                            <img src={EPL} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-1 md:ml-2">Premier League</h2>
                    </div>

                    <div className='flex items-center'>

                        <div className="w-4 h-4 md:w-4 md:h-4 text-white">
                            <img src={SolidTime} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-[200] text-[#9A9A9A] ml-1 md:ml-2">
                            
                            {FormatDate(game.date)}
                        </h2>
                    </div>
                </div>


                <div className="flex flex-col md:flex-row text-left justify-start md:items-center md:justify-between md:space-x-4">
                    {/* <div className='flex mb-[1.5rem] md:mb-0'> */}
                        <div className="flex flex-row items-center space-x-2 md:space-x-14">

                            <div className="flex items-center">
                                <div className='border-[1px] border-[#2A2A2A] rounded-full card h-[30px] w-[30px] md:h-[50px] md:w-[50px] overflow-hidden md:p-[7px] bg-transparent glass-card flex items-center justify-center' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                                    <img
                                        src={game.homeLogo}
                                        alt="Home"
                                        className="w-4 h-4 md:w-8 md:h-8 rounded-full object-cover"
                                    />
                                </div>

                                <span className="ml-1 md:ml-3 text-[0.5rem] md:text-[0.7rem] text-[#9A9A9A] font-[200] whitespace-nowrap">{game.homeName}</span>
                            </div>


                            <span className="text-xl font-bold text-green-500">/</span>
                            

                            <div className="flex items-center">
                                <div className='border-[1px] border-[#2A2A2A] rounded-full card h-[30px] w-[30px] md:h-[50px] md:w-[50px] overflow-hidden md:p-[7px] bg-transparent glass-card flex items-center justify-center' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                                    <img
                                        src={game.awayLogo}
                                        alt="Home"
                                        className="w-4 h-4 md:w-8 md:h-8 rounded-full object-cover"
                                    />
                                </div>
                                <span className="ml-1 md:ml-3 text-[0.5rem] md:text-[0.7rem] text-[#9A9A9A] font-[200] whitespace-nowrap">{game.awayName}</span>
                            </div>
                        </div>
                    {/* </div> */}

                    <div className="">
                        
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between mt-5 md:mt-5">
                <div className='flex items-center mb-2 md:mb-0'>
                    <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                        <img src={RobotImage} alt="Icon" className="text-[#00FF87]" />
                    </div>
                    <h2 className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-2">Markets</h2>
                </div>

                <p className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A]">
                    Cost/market: 1 Prediction point
                </p>
            </div>

            <div className='mt-3'>
                <Markets tabs={marketState} game={game}/>
            </div>

        </div>
    )
}
