import React, { useState } from 'react'
import { TaskCard } from './TaskCard';
import CopyTaskImage from '../../../asset/copy-task.png';
import SurveyModal from '../../../Components/Modals/SurveyModal';

const tasks: any[] = [
    {
        id: 1,
        index: 1,
        reward: 300,
        steps: [
            {
                id: 1,
                text: 'create an account on betracle.',
            },
            {
                id: 2,
                text: 'verify account.',
            }
        ],
        btnTxt: 'get started',
        earned: 300,
    },
    {
        id: 2,
        index: 2,
        reward: 500,
        steps: [
            {
                id: 1,
                text: 'earn more points on each referral that joins betracle.',
            },
            {
                id: 2,
                text: 'share referral link with friends.',
            }
        ],
        btnTxt: 'copy link',
        btnLink: 'https://test.betracle.ai',
        earned: 200,
    },
    {
        id: 3,
        reward: 700,
        steps: [
            {
                id: 1,
                text: 'like share and retweet this post',
            },
            
        ],

        btnTxt: 'get started',
        earned: 100,
    }
    
]

export const Tasks: React.FC<any> = () => {
    const [showSurveyForm, setShowSurveyForm] = useState(false);

    return (
        <div>
            <div className='flex p-4 bg-[#00170C] border-radius rounded-lg w-[40%] md:w-[10%] mb-10'>
                <img src={CopyTaskImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-5" />
                <span className='text-[#00FF87] text-[0.7rem] md:text-[1rem]'>Tasks</span>
            </div>

            <div className="flex flex-wrap justify-between">
                {tasks.map((task, index) => (
                    <TaskCard key={index} task={task} setShowSurveyForm={setShowSurveyForm} showSurveyForm={showSurveyForm}/>
                ))}
            </div>

            {showSurveyForm && <SurveyModal setShowSurveyForm={setShowSurveyForm} showSurveyForm={showSurveyForm}/>
}
        </div>
    )
}
