export const SUCCESS_COLOR = "#00FF87";
export const FAILURE_COLOR = "";

export function FormatDate(inputDateString: any) {
  const date = new Date(inputDateString);

  const options: any = { 
    weekday: "short", 
    day: "numeric", 
    month: "short",
    hour: "2-digit", // 2-digit ensures leading zeroes
    minute: "2-digit", // 2-digit ensures leading zeroes
    hour12: false // Set to false for 24-hour format
  };
  
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
}


export function formatDateString(inputDateString: any) {
  const date = new Date(inputDateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function shortenURL(url: any, startChars = 18, endChars = 12) {
  if (url.length <= startChars + endChars + 5) {
    return url; // If the URL is too short, return the whole URL
  }

  const start = url.substring(0, startChars); // Extract initial characters
  const end = url.substring(url.length - endChars); // Extract end characters

  return `${start}...${end}`; // Concatenate and add ellipsis in the middle
}

export function formatTime(dateString: any) {

  const dateTime = new Date(dateString);

  // Extracting hours and minutes from the Date object
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  // Formatting the time
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
}

export function roundPointsToNearestWhole(points: any) {
  let roundedPoints: any = {};
  
  for (let key in points) {
    if (points.hasOwnProperty(key)) {
      roundedPoints[key] = Math.round(points[key]);
    }
  }
  
  return roundedPoints;
}

export function formatToPercentage(value: any) {
  // Convert to percentage
  const percentage = value * 100;

  // Handle very small percentages separately
  if (percentage < 0.01) {
      return percentage.toFixed();
  }

  // Otherwise, fix to 2 decimal places
  return percentage.toFixed();
}


export class Utils {
  static PredictionPoint = 0;
  static errorStatusCode = 0;

  // constructor() {
  //   const USER:any = localStorage.getItem("USER_DETAILS");
  //   let parsedUser = JSON.parse(USER);
  //   Utils.PredictionPoint = parsedUser
  // }

  static updatePredictionPoint(newValue?: any) {
    Utils.PredictionPoint = newValue;
    return Utils.PredictionPoint;
  }
}
