import React, { useState } from 'react';
import { Step1 } from './onBoarding/step1';
import { Step2 } from './onBoarding/step2';
import { Step3 } from './onBoarding/step3';
import { Step4 } from './onBoarding/step4';
import { Step5 } from './onBoarding/step5';
import { Terms } from './PredictionResults/Terms';


const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleTermsStep = () => {
    setCurrentStep(6);
  };

  return (
    <div>
      {currentStep === 1 && <Step1 onNextStep={handleNextStep} onSkipSteps={handleTermsStep} />}
      {currentStep === 2 && <Step2 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} onSkipSteps={handleTermsStep}/>}
      {currentStep === 3 && <Step3 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} onSkipSteps={handleTermsStep}/>}
      {currentStep === 4 && <Step4 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} onSkipSteps={handleTermsStep}/>}
      {currentStep === 5 && <Step5 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep}/>}
      {currentStep === 6 && <Terms onFinalStep={handleNextStep}  />}
    </div>
  );
};

export default Onboarding;
