
import React from 'react'
// import NoPrediction from './NoPrediction';
import Liverpool from '../../asset/liverpool.png';
import Mancity from '../../asset/mancity.png';
import { motion } from 'framer-motion';
import { formatToPercentage } from '../../Utils/utils';
import { NoPrediction } from './NoPrediction';

const GoalGoal: React.FC<any> = ({ game }) => {
    const prediction = (game.prediction !== null && game.prediction?.goal_no_goal !== '') ? JSON.parse(game?.prediction?.goal_no_goal) : null;


    const truePercentage = formatToPercentage(prediction.True);
    const falsePercentage = formatToPercentage(prediction.False);


    return (
        <>
            {
                prediction !== null ? (<div className='flex flex-row gap-5 prediction-component'>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}

                    >
                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                            <div className="mt-2 ml-3 mb-3 w-[12rem] md:w-[15rem] flex items-center">
                                <div className='mr-4 flex'>
                                    <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                    <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />

                                    <p className={`text-[0.8rem] md:text-[1.1rem] ${falsePercentage > truePercentage ? 'text-[#00FF87]' : 'text-[#F44336]'} font-[900] font-[Orbitron] ml-3`}>No Goal</p>
                                </div>
                                <div className={``}>
                                    <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                    <p className="text-[1rem] md:text-2xl font-extrabold font-[Orbitron] text-[#FF9800]">{falsePercentage}<span className="-top-2 -right-2 text-base">%</span></p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 1 }}

                    >
                        <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                            <div className="mt-2 ml-3 mb-3 w-[15rem] md:w-[18rem] flex items-center">
                                <div className='mr-4 flex'>
                                    <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                    <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />

                                    <p className={`text-[0.8rem] md:text-[1.1rem] ${truePercentage > falsePercentage ? 'text-[#00FF87]' : 'text-[#F44336]'}  font-[900] font-[Orbitron] ml-3`}>Goal Goal</p>
                                </div>
                                <div className={``}>
                                    <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                    <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] text-[#FF9800]`}>{truePercentage}<span className="-top-2 -right-2 text-base">%</span></p>
                                </div>
                            </div>
                        </div>
                    </motion.div>



                </div>)
                    :
                    (
                        <NoPrediction />
                    )
            }


        </>
    )
}

export default GoalGoal;

