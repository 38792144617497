import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../../Utils/Loader/Loader';
import { VerifyEmailMessage } from '../../../Redux/apiCalls';

const MySwal = withReactContent(Swal)

export default function VerifyEmailPage() {
    const [isLoading, setIsLoading] = useState(false);
    const { token, email } = useParams();
    const navigate = useNavigate();
    
    const VerifyEmail = async () => {
        setIsLoading(true);
        try {
            const info = await VerifyEmailMessage(token);
        
            if (info.message === 'Successful') {
                MySwal.fire({
                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Account verified!</p>,
                    text: "Your account has been successfully verified.",
                    icon: "success",
                    allowOutsideClick: false,
                }).then((result: any) => {
                    if (result.isConfirmed) {
                     navigate('/');
                    }
                  });
                setIsLoading(false);

            }
            else if(info.message === 'User with this token not found') {
                setIsLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Verification failed</p>,
                    text: "The user token has expired or user not found.",
                    allowOutsideClick: false,
                  }).then((result: any) => {
                    if (result.isConfirmed) {
                     navigate('/');
                    }
                  });

            }
            else if(info.message === 'Invalid or expired token') {
                setIsLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Verification failed</p>,
                    text: "The user token has expired",
                    allowOutsideClick: false,
                  }).then((result: any) => {
                    if (result.isConfirmed) {
                     navigate('/');
                    }
                  });

            }
            else if(info.message === 'Account verified') {
                setIsLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Account verified</p>,
                    text: "This account has already been verified.",
                    allowOutsideClick: false,
                  }).then((result: any) => {
                    if (result.isConfirmed) {
                     navigate('/');
                    }
                  });

            }
            else {
                setIsLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Verification failed</p>,
                    text: "An error has occurred, please try again later.",
                    allowOutsideClick: false,
                  }).then((result: any) => {
                    if (result.isConfirmed) {
                     navigate('/');
                    }
                  });
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);

        }
    }
    useEffect(() => {
        VerifyEmail()
    }, []);

    return (
        <div>
            {isLoading && <Loader />}
        </div>
    )
}
