import React, { useState } from 'react';
import Stadium from '../../../asset/bg-image.jpg';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResetPassword } from '../../../Redux/apiCalls';
import Loader from '../../../Utils/Loader/Loader';
import EyeOpen from '../../../asset/eye.svg';
import EyeSlash from '../../../asset/eye-off.svg';

const ResetPasswordFeature: React.FC<any> = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { token } = useParams();
    const navigate = useNavigate();


    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleResetPassword = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if (newPassword === '' || confirmPassword === '') {
            toast.warn("Fields are required.");
            setIsLoading(false);

        }
        else if (newPassword !== confirmPassword) {
            toast.warn("Passwords do not match");
            setIsLoading(false);
        }
        else {
            try {
                let data = {
                    newPassword: newPassword
                }
                const _tmp = await ResetPassword(token, data);
                if (_tmp.data.message === 'Password reset successful') {
                    navigate('/');
                    toast.success(_tmp.data.message);
                    setIsLoading(false);

                }
                else {
                    toast.warn(_tmp.data.message);
                    setIsLoading(false);

                }
            } catch (error: any) {
                toast.warn(error.message);
                setIsLoading(false);

            }
        }
    }

    return (
        <div
            className="min-h-screen bg-cover bg-center bg-no-repeat"
            style={{
                backgroundImage: `url(${Stadium})`,
            }}
        >
            {isLoading && <Loader />}
            <ToastContainer autoClose={5000} />
            <div className="flex justify-center items-center min-h-screen p-2 md:p-0">

                <div className=" p-8 border-radius shadow-lg w-full md:w-96 glass-card">
                    <h1 className="text-2xl font-semibold mb-4 text-[#8D8D8D] py-8 font-medium">Reset Password</h1>
                    <form>
                        <div className="mb-4 relative">

                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.8rem] md:text-[1rem]"
                                placeholder='New Password'
                                style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />

                            <button
                                className="absolute inset-y-0 right-0 px-3 py-2"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ?
                                    <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                    :
                                    <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                }
                            </button>
                        </div>
                        <div className="mb-4 relative">

                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.8rem] md:text-[1rem]"
                                placeholder='Confirm Password'
                                style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            <button
                                className="absolute inset-y-0 right-0 px-3 py-2"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                {showConfirmPassword ?
                                    <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                    :
                                    <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                }
                            </button>
                        </div>
                        <div className="my-16">
                            <button
                                type="submit"
                                className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full rounded"
                                onClick={handleResetPassword}
                            >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default ResetPasswordFeature;
