import React from 'react'
// import TermsImage from '../../asset/terms.png';
import TImage from '../../asset/icons/ball.png';
import { useDispatch } from 'react-redux';
import { handleOnBoarding } from '../../Redux/userRedux';


export const Terms: React.FC<any> = ({ onFinalStep }) => {
  const dispatch = useDispatch();

  const handleBoarding = () => {
    dispatch(handleOnBoarding());
    onFinalStep();
    
};
  return (
    <div>
      <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

        <div className="modal-overlay absolute w-full h-full bg-[#000000] opacity-50"></div>

        <div className="modal-container w-11/12 md:max-w-md mx-auto absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
        >
          <div className="modal-content py-4 text-left px-6">
            {/* <div className='relative bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card' style={{ borderRadius: '50%'}}>
<h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>5</h1>
</div> */}

            <img src={TImage} alt='terms' className='w-100 h-100' />

            <div className='mb-10'>
              <h1 className='text-[#00FF87] font-extrabold font-[Orbitron] mb-5 text-[0.7rem] md:text-[0.9rem]'>Finally! The Terms</h1>
              <ol className='list-decimal pl-6 mb-5'>
                <li className='mb-3 text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                  <p className='text-justify '>
                  Betracle AI predictions are for entertainment purposes only and should not be used for betting or gambling with real money, as accuracy is not guaranteed.
                  </p>
                </li>
                <li className='mb-3 text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                  Each market prediction costs 1 prediction point.
                </li>
              </ol>
            </div>

            <div className='flex items-center justify-between mb-3'>

              <button onClick={handleBoarding} className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                Got it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
