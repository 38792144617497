import React from 'react'
import './Loader.module.css';

export default function Loader() {
  return (
    <div className="overlay">
    <div className="overlay__inner">
        <div className="overlay__content"><span className="spinner"></span></div>
    </div>
</div>
  )
}
