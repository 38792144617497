import React from 'react'
import { motion } from 'framer-motion';
import { AirDropCountDown } from './AirDropCountDown';
import { AirDropTopUsers } from './AirDropTopUsers';
import AccountCard from '../../../Components/AccountCard';

export const AirDropSideBar = () => {
    return (
        <aside className=" text-white w-1/4 hidden lg:block p-3  right-0 h-full">
            <nav>
            {/* <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                >
                    <AccountCard />
                </motion.div> */}
                {/* <div className='mb-[100px]'></div> */}
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                >
                    <AirDropCountDown />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 2.5 }}
                >
                    <AirDropTopUsers />
                </motion.div>
            </nav>
        </aside>
    )
}
