import React from 'react'
import Stadium from '../asset/stadium.png';
import SolidTime from '../asset/solid_time.png';
// import Left from '../asset/Group.png';
// import DownFill from '../asset/left-fill.png';
import EPL from '../asset/EPL.png';
import Soccer from '../asset/soccer.png';
import PredictionImage from '../asset/prediction.png';

import Slash from '../asset/slash.png';
import Markets from './Markets';
import { motion } from 'framer-motion';
import Liverpool from '../asset/liverpool.png';
import Mancity from '../asset/mancity.png';
import WinDrawLose from './PredictionResults/WinDrawLose';
import { FormatDate } from '../Utils/utils';
import WDL from './HistoryPredictions/WDL';
import CS from './HistoryPredictions/CS';
import GNG from './HistoryPredictions/GNG';
import OE from './HistoryPredictions/OE';
import OV1 from './HistoryPredictions/OV1';
import OV2 from './HistoryPredictions/OV2';
import OV3 from './HistoryPredictions/OV3';
import { DC } from './HistoryPredictions/DC';
import { HTFT } from './HistoryPredictions/HTFT';

export const HistoryCard: React.FC<any> = ({ history }) => {
    console.log(history)
    return (
        <div className="relative mt-0 bg-[#0B0B0B] bg-cover bg-center bg-no-repeat p-5 border-radius rounded-lg border-[1px] border-[#2A2A2A] mb-5" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A', backgroundImage: `url(${Stadium})` }}>
            {/* <div className="absolute inset-0 bg-black opacity-25"></div> */}
            <div className="md:flex items-center justify-start md:space-x-10 md:mb-10">

                <div className='flex md:flex-col flex-row space-x-2 md:space-x-0 mb-4 md:mb-0'>
                    <div className='flex items-center md:mb-1'>
                        <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                            <img src={Soccer} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-1 md:ml-2">Soccer</h2>
                    </div>

                    <div className='flex items-center md:mb-1'>
                        <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                            <img src={EPL} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-1 md:ml-2">Premier League</h2>
                    </div>

                    <div className='flex items-center'>

                        <div className="w-4 h-4 md:w-4 md:h-4 text-white">
                            <img src={SolidTime} alt="Icon" className="text-[#00FF87]" />
                        </div>

                        <h2 className="text-[0.5rem] md:text-[0.7rem] font-[200] text-[#9A9A9A] ml-1 md:ml-2">
                            
                            {FormatDate(history?.game.date)}
                        </h2>
                    </div>
                </div>


                <div className="flex flex-col md:flex-row text-left justify-start md:items-center md:justify-between md:space-x-4">
                    <div className='flex mb-[1.5rem] md:mb-0'>
                        <div className="rounded-lg flex items-center space-x-2 md:space-x-14">

                            <div className="flex items-center">
                                <div className='border-[1px] border-[#2A2A2A] rounded-full card h-[30px] w-[30px] md:h-[50px] md:w-[50px] overflow-hidden md:p-[7px] bg-transparent glass-card flex items-center justify-center' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                                    <img
                                        src={history?.game.homeLogo}
                                        alt="Home"
                                        className="w-4 h-4 md:w-8 md:h-8 rounded-full object-cover"
                                    />
                                </div>
                                <span className="ml-1 md:ml-3 text-[0.7rem] md:text-[0.9rem] text-[#9A9A9A] font-[200] whitespace-nowrap">{history?.game.homeName}</span>
                            </div>


                            <span className="text-xl font-bold text-green-500">/</span>
                            {/* <img
                                src={Slash}
                                alt="slash"
                                className="w-4 h-4 md:w-4 md:h-8 rounded-full object-cover"
                            /> */}

                            <div className="flex items-center">
                                <div className='border-[1px] border-[#2A2A2A] rounded-full card h-[30px] w-[30px] md:h-[50px] md:w-[50px] overflow-hidden md:p-[7px] bg-transparent glass-card flex items-center justify-center' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                                    <img
                                        src={history?.game.awayLogo}
                                        alt="Home"
                                        className="w-4 h-4 md:w-8 md:h-8 rounded-full object-cover"
                                    />
                                </div>
                                <span className="ml-1 md:ml-3 text-[0.7rem] md:text-[0.9rem] text-[#9A9A9A] font-[200] whitespace-nowrap">{history?.game.awayName}</span>
                            </div>
                        </div>

                    </div>

                    <div className="">
                        {/* <h5 className='font-[200] text-[0.9rem] md:text-[1rem] text-[#838383]'>Predictions</h5>
                        <h5 className="text-[#00FF87] font-extrabold font-[Oxta]">60K</h5> */}
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between mt-5">
                <div className='flex items-center mb-2 md:mb-0'>
                    <div className="w-4 h-4 md:w-4 md:h-4 text-white mr-0">
                        <img src={PredictionImage} alt="Icon" className="text-[#00FF87]" />
                    </div>

                    <h2 className="text-[0.6rem] md:text-[0.7rem] font-regular text-[#9A9A9A] ml-2">AI Predictions</h2>
                </div>

                <p className="text-[0.6rem] md:text-[0.7rem] font-regular text-[#9A9A9A]">
                    {/* Cost/market: 1 Prediction point */}
                </p>
            </div>

            <div className='mt-3 mb-5 overflow-x-auto no-scrollbar'>
               {(history.market.market_name === "1X2") && <WDL game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Correct Score") && <CS game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Goal No Goal") && <GNG game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Odd/Even") && <OE game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Over/Under 1.5") && <OV1 game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Over/Under 2.5") && <OV2 game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Over/Under 3.5") && <OV3 game={history.prediction} team={history.game}/>}

               {(history.market.market_name === "Double Chance") && <DC game={history.prediction} team={history.game}/>}
               {(history.market.market_name === "Halftime/Fulltime") && <HTFT game={history.prediction} team={history.game}/>}

            </div>

            <div className='mt-3 flex flex-col md:flex-row md:space-x-4'>
                <div className='p-2'>
                    <h2 className="text-[0.6rem] md:text-[0.7rem] font-regular text-[#9A9A9A]">Market</h2>
                    <p className="text-[0.8rem] md:text-[1.1rem] text-[#D9D9D9] font-[900] font-[Orbitron] md:ml-1">{history?.market.market_name}</p>
                </div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent bg-gradient-to-r from-[#150B22] to-[#042635] glass-card' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                        <div className="mt-2 ml-3 mb-3 w-[14rem] ">
                            <h2 className="text-[0.5rem] md:text-[0.6rem] font-regular text-[#9A9A9A] mb-1">Final results</h2>
                            {history.result !== null ? (<div className="flex items-center">
                                <img
                                    src={Liverpool}
                                    alt="result"
                                    className="w-4 h-4 md:w-6 md:h-6 rounded-full object-cover"
                                />
                                <span className="ml-1 md:ml-3 text-[0.8rem] md:text-[0.9rem] text-[#9A9A9A] font-[200] whitespace-nowrap">Liverpool wins</span>
                            </div>) : (
                                 <span className="ml-1 md:ml-3 text-[0.8rem] md:text-[0.9rem] text-[#9A9A9A] font-[200] whitespace-nowrap">Coming soon</span>
                            )}
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}
