import React from 'react'
// import NoPrediction from './NoPrediction';
import Liverpool from '../../asset/liverpool.png';
import Mancity from '../../asset/mancity.png';
import { motion } from 'framer-motion';
import { roundPointsToNearestWhole } from '../../Utils/utils';
import { NoPrediction } from './NoPrediction';


const WinDrawLose: React.FC<any> = ({ game, decreasePoint }) => {

    const prediction = (game.prediction !== null && game.prediction?.win_draw !== '') ? JSON.parse(game?.prediction?.win_draw) : null;

    console.log(game);
    let formattedPrediction = roundPointsToNearestWhole(prediction);

    const predictions = {
        Home: formattedPrediction?.Home || 0,
        Draw: formattedPrediction?.Draw || 0,
        Away: formattedPrediction?.Away || 0,
      };
      
      // Determine the order of the values
      const sortedPredictions = Object.entries(predictions).sort((a, b) => b[1] - a[1]);
      
      // Assign colors based on the sorted order
      const colors = {
        [sortedPredictions[0][0]]: 'text-[#00FF87]', // Highest value
        [sortedPredictions[1][0]]: 'text-[#FF9800]', // Second highest value
        [sortedPredictions[2][0]]: 'text-[#DD2E44]', // Lowest value
      };

    return (
        <>
            {
                prediction !== null  ? (<div className='flex flex-row gap-5 prediction-component'>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    
                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 w-[7rem] md:w-[10rem] flex items-center">
                            <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-3" />
                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${colors.Home}`}>{predictions?.Home}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 w-[8rem] md:w-[10rem] flex items-center">
                            <div className='mr-3 flex'>
                                <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                            </div>
                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${colors.Draw}`}>{predictions?.Draw}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 w-[7rem] md:w-[10rem] flex items-center">
                            <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-3" />
                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${colors.Away}`}>{predictions?.Away}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>

            </div>)
            : (
                <NoPrediction />
            )
        }
        </>
    )
}

export default WinDrawLose;
