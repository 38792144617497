import React, { useState } from 'react'

export const TaskCard: React.FC<any> = ({ task,  setShowSurveyForm, showSurveyForm }) => {
    

    return (
        <div className="w-full md:w-[28rem] mb-5 md:mb-0">
            <div className={`border-[1px] border-[#2A2A2A] border-radius rounded-lg w-full h-full ${task.index ? 'bg-transparent' : 'bg-gradient-to-r from-[#150B22] to-[#042635] '}  glass-card p-5`} style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                <div className="flex items-center justify-between mb-10">
                    <div className='p-5 bg-[#0B0B0B] border-radius rounded-lg w-20 flex items-center justify-center border-[1px] border-[#2A2A2A] border-radius'>
                        {
                            task.index && <span className='text-lg font-bold text-[#D9D9D9] text-2xl md:text-4xl font-extrabold font-[Orbitron]'>{task.index}</span>
                        }

                        {
                            !task.index && <div className='flex flex-col'>
                                <span className='text-[#D9D9D9]'>Daily</span>
                                <span className='text-[#545454]'>Task</span>
                            </div>
                        }
                    </div>


                    <div className='flex flex-col'>
                        <span className='text-[#C6C6C6] text-[0.7rem] md:text-[0.8rem]'>Reward</span>
                        <span className=' font-bold text-[#00FF87] text-[0.9rem] md:text-[1.4rem] font-extrabold font-[Orbitron]'>
                            +{task.reward} xp
                        </span>
                    </div>
                </div>


                <div className='h-[80px]'>
                    <ol className='list-decimal pl-6 mb-5'>
                        {
                            task.steps.map((step: any, i: number) => (
                                <li key={i} className='mb-3 text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                                    <p className='text-justify '>
                                        {step.text}
                                    </p>
                                </li>
                            ))
                        }

                    </ol>
                </div>



                <div className="flex items-center justify-between mt-10">

                    <button onClick={() => {
                        
                        if(task.btnLink) {
                            window.open(task.btnLink, '_blank')
                        }

                        if(task.id === 3) {
                            setShowSurveyForm(!showSurveyForm)
                        }

                        }} className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center">
                        <span className="font-[700] text-[0.9rem]">
                            {task.btnTxt}
                        </span>
                    </button>



                    <div className='flex flex-col'>
                        <span className='text-[#D9D9D9] text-[0.7rem] md:text-[1rem]'>Earned</span>
                        <span className='text-[#FF9E0D] text-[0.7rem] md:text-[0.8rem] text-right'>
                            {task.earned} xp
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


