import { createSlice } from "@reduxjs/toolkit";

const marketSlice = createSlice({
    name: "market",
    initialState: {
        markets: [],
        isFetching: false,
        error: false
    },
    reducers: {
        fetchMarketsStart: (state) => {
            state.isFetching = true;
        },
        fetchMarketSuccess: (state, action) => {
            state.isFetching = false;
            state.markets = action.payload;
            state.error = false;
        },
        fetchMarketsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
});

export const { fetchMarketsStart, fetchMarketSuccess, fetchMarketsFailure } = marketSlice.actions;
export default marketSlice.reducer;