import React from 'react'
// import NoPrediction from './NoPrediction';
import Liverpool from '../../asset/liverpool.png';
import Mancity from '../../asset/mancity.png';
import { motion } from 'framer-motion';
import { roundPointsToNearestWhole } from '../../Utils/utils';
import { NoPrediction } from './NoPrediction';

const DoubleChance: React.FC<any> = ({ game }) => {
    const prediction = (game.prediction !== null && game.prediction?.double_chance !== '') ? JSON.parse(game?.prediction?.double_chance) : null;

    let formattedPrediction = roundPointsToNearestWhole(prediction);

   
    const predictions = {
        Home_Win_Draw: formattedPrediction['1X'] || 0,
        Any_Body_Win: formattedPrediction['12']|| 0,
        Away_Win_Draw: formattedPrediction['X2'] || 0,
      };

      // Determine the order of the values
      const sortedPredictions = Object.entries(predictions).sort((a, b) => b[1] - a[1]);
      
      // Assign colors based on the sorted order
      const colors = {
        [sortedPredictions[0][0]]: 'text-[#00FF87]', // Highest value
        [sortedPredictions[1][0]]: 'text-[#FF9800]', // Second highest value
        [sortedPredictions[2][0]]: 'text-[#DD2E44]', // Lowest value
      };

    return (
        <>
            {prediction !== null  ? (<div className='flex flex-row gap-5 prediction-component '>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 w-[10rem] flex items-center">
                            <div className='flex items-center mr-3'>
                                <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-1" />
                                <p className="text-[0.9rem] md:text-[1.1rem] text-[#D9D9D9] font-[900] font-[Orbitron]">1X</p>
                            </div>
                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${colors.Home_Win_Draw}`}>{predictions?.Home_Win_Draw}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>


                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 mr-3 w-[10rem] flex items-center">
                            <div className='mr-3 flex items-center'>
                                <img src={game.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                                <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />

                                <p className="text-[0.9rem] md:text-[1.1rem] text-[#D9D9D9] font-[900] font-[Orbitron] ml-1">1,2</p>
                            </div>
                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${colors.Any_Body_Win}`}>{predictions?.Any_Body_Win}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>


                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}

                >
                    <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-transparent glass-card' style={{boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)'}}>
                        <div className="mt-2 ml-3 mb-3 w-[10rem] flex items-center">
                            <div className='flex items-center mr-3'>
                                <img src={game.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-1" />
                                <p className="text-[0.9rem] md:text-[1.1rem] text-[#D9D9D9] font-[900] font-[Orbitron]">2X</p>
                            </div>

                            <div className={``}>
                                <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                <p className={`text-[0.9rem] md:text-[1.1rem] font-extrabold font-[Orbitron] ${colors?.Away_Win_Draw}`}>{predictions?.Away_Win_Draw}<span className="-top-2 -right-2 text-base">%</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>


            </div>) : (
                <NoPrediction />
            )}

            {/* <div className='flex flex-col md:flex-row md:gap-5 pb-4'>
                        <div className="mt-2 w-[18rem]">
                            <div className="mt-3 flex items-center px-4 py-4 bg-[#0B0B0B] border-radius" style={{ boxShadow: '5px 6px 0 0 #000000' }}>
                                <div className="flex flex-col text-left">
                                    <div className={`${prediction.home_or_draw ? 'text-[#00FF87]' : 'text-[#F44336]'} mb-5`}>
                                        <p className={`text-2xl font-extrabold font-[Oxta]`}>{prediction.home_or_draw == true ? "True" : "False"}<span className="-top-2 -right-2 text-base"></span></p>
                                        <h5 className='text-sm'>Probability</h5>
                                    </div>
                                    <div className="flex items-center mb-0">
                                        <img src={game.homeLogo} alt="Icon" className="w-6 h-6 mr-1" />
                                        <p className="text-[1rem] text-[#838383] font-[200]">1X</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 w-[18rem]">
                            <div className="mt-3 flex items-center px-4 py-4 bg-[#0B0B0B] border-radius" style={{ boxShadow: '5px 6px 0 0 #000000' }}>
                                <div className="flex flex-col text-left">
                                    <div className={`${prediction.home_or_away ? 'text-[#00FF87]' : 'text-[#F44336]'} mb-5`}>
                                        <p className="text-2xl font-extrabold font-[Oxta]">{prediction.home_or_away == true ? "True" : "False"}<span className="-top-2 -right-2 text-base"></span></p>
                                        <h5 className='text-sm'>Probability</h5>
                                    </div>
                                    <div className="flex items-center mb-0">

                                        <p className="text-[1rem] text-[#838383] font-[200]">12</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 w-[18rem]">
                            <div className="mt-3 flex items-center px-4 py-4 bg-[#0B0B0B] border-radius" style={{ boxShadow: '5px 6px 0 0 #000000' }}>
                                <div className="flex flex-col text-left">
                                    <div className={`${prediction.away_or_draw ? 'text-[#00FF87]' : 'text-[#F44336]'} mb-5`}>
                                        <p className="text-2xl font-extrabold font-[Oxta]">{prediction.away_or_draw == true ? "True" : "False"}<span className="-top-2 -right-2 text-base"></span></p>
                                        <h5 className='text-sm'>Probability</h5>
                                    </div>
                                    <div className="flex items-center mb-0">
                                        <img src={game.awayLogo} alt="Icon" className="w-6 h-6 mr-1" />
                                        <p className="text-[1rem] text-[#838383] font-[200]">X2</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


        </>
    )
}

export default DoubleChance;
