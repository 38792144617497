import React from 'react'
import Lighting from '../asset/lighting12.png';
import { useSelector } from 'react-redux';

export const PredictionPoints = () => {
  const point = useSelector((state: any) => state.user.predictionPoint);
  return (
    <div className="mt-0 md:mt-4 ">
    {/* New Section */}
    <div className="mt-0 bg-gradient-to-r from-[#FB9600] to-[#4F501D] p-5 border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
      <div className="text-left">
        <div className="flex items-center mb-3">
          <img src={Lighting} alt="Icon" className="w-10 h-10 md:w-12 md:h-12 mr-3" />
          <p className="text-[0.9rem] md:text-[1rem] text-[#000000] font-[700]">Prediction Points Balance</p>
        </div>
        <p className="text-[#000000] text-3xl md:text-4xl font-extrabold font-[Orbitron]  mb-1">{point}</p>

      </div>
    </div>
  </div>
  )
}
