import React from 'react'
import Hamburger from '../asset/hamburger.png';
import AccountCard from './AccountCard';

export const Header: React.FC<any> = ({ toggleSidebar }) => {
    return (
        <header className="flex justify-between items-center  p-3 md:hidden">
            <AccountCard />

            <div className='' onClick={toggleSidebar}>
                <img src={Hamburger} alt="BLogo" className="w-12 h-12" />
            </div>

            {/* <div className="flex items-center justify-start">

                <a href="#" className='flex items-center'>
                    <img src={Exclude} alt="BLogo" className="w-50 h-16" />
                   
                </a>
            </div> */}
        </header>
    )
}
