import React from 'react'

export const Step2: React.FC<any> = ({ onNextStep, onPreviousStep , onSkipSteps}) => {
    return (
        <div>
            <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

                <div className="modal-overlay absolute w-full h-full bg-[#000000] opacity-50">
                    <div className='absolute bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card left-[20%] top-[260px] md:top-[40%] z-10' style={{ borderRadius: '50%', boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                        <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>2</h1>
                    </div>
                </div>

                <div className="modal-container top-[15px] md:top-[100px] left-10 w-[72%] md:w-[25%]  absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
                >
                    <div className="modal-content py-4 text-left px-6">
                        {/* <div className='relative bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card' style={{ borderRadius: '50%'}}>
            <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>2</h1>
        </div> */}

                        <div className='flex items-center justify-between mb-10'>
                            <button onClick={onSkipSteps} className="bg-transparent text-[#00FF87] py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                                skip
                            </button>
                            <h1 className='text-right text-[#545454] mb-3 text-[0.6rem] md:text-[0.7rem]'>2/5</h1>
                        </div>

                        <div className='mb-10'>
                            <h1 className='text-[#00FF87] font-extrabold font-[Orbitron] mb-2 text-[0.7rem] md:text-[0.9rem]'>Get latest predictions</h1>
                            <p className='text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                                this section contains all the latest predictions generated by Betracle AI, you can search for specific teams and get predictions on different betting markets
                            </p>
                        </div>

                        <div className='flex items-center justify-between mb-3'>
                            <button onClick={onPreviousStep} className="bg-transparent text-[#00FF87] py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                                Prev
                            </button>

                            <button onClick={onNextStep} className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
