import { useState } from 'react'
import { useSelector } from 'react-redux';
import AccountImage from '../asset/account12.png';
import AccountModal from './Modals/AccountModal';

export default function AccountCard() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const userState = useSelector((state: any) => state.user.currentUser);

    const openAccountModal = () => {
        setIsModalOpen(true);
    };

    const closeAccountModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div>
            <div onClick={openAccountModal} className="border-[1px] border-[#2A2A2A] border-radius rounded-lg p-2 cursor-pointer">

                <div className="mt-0 bg-[#0B0B0B] p-3 border-radius rounded-lg width" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
                    <div className="text-left">
                        <div className="flex items-center">
                            <img src={AccountImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-5" />
                            <p className="text-[0.9rem] md:text-[1rem] text-[#838383] font-[200]">{userState.user.username}</p>
                        </div>


                    </div>
                </div>

            </div>
            <AccountModal isOpenAccount={isModalOpen} closeAccountModal={closeAccountModal} />
        </div>
    )
}
