import React from 'react'
import GoogleFormViewer from '../GoogleSurvey'

export default function SurveyModal({ setShowSurveyForm, showSurveyForm }: any) {
    return (
        <div>
            <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

                <div onClick={() => {
                    setShowSurveyForm(false)
                }} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-50"></div>

                <div className="modal-container w-11/12 md:max-w-md mx-auto absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
                >
                    <GoogleFormViewer />
                </div>
            </div>
        </div>
    )
}


