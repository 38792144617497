import { createSlice } from "@reduxjs/toolkit";
import { FetchGames } from "./apiCalls";
import { RootState } from "./store";

export interface Team {
  name: string;
  logo: string;
}

export interface Game {
  fixtureId: number;
  date: Date;
  name: string;
  home: Team;
  away: Team;
}

export interface GamesState {
  currentGames: Game[];
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null | any;
}

const initialState: GamesState = {
  currentGames: [],
  loading: "idle",
  error: null,
};

// const gamesSlice = createSlice({
//     name: 'games',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//       builder
//         .addCase(FetchGames.pending, (state) => {
//           state.loading = 'pending';
//         })
//         .addCase(FetchGames.fulfilled, (state, action) => {
//           state.loading = 'fulfilled';
//           state.currentGames = action.payload;
//         })
//         .addCase(FetchGames.rejected, (state, action) => {
//           state.loading = 'rejected';
//           state.error = action.error.message;
//         });
//     },
//   });

const gameSlice = createSlice({
  name: "game",
  initialState: {
    games: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    fetchGamesStart: (state) => {
      state.isFetching = true;
    },
    fetchGamesSuccess: (state, action) => {
      state.isFetching = false;
      state.games = action.payload;
      state.error = false;
    },
    fetchGamesFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    handleGameSearch: (state, action) => {
        const searchGames = state.games.filter((el: any) => {
            return (
                el.homeName.toLowerCase().includes(action.payload.toLowerCase()) ||
                el.awayName.toLowerCase().includes(action.payload.toLowerCase())
            );
        });
    },
  },
});

export const { fetchGamesStart, fetchGamesSuccess, fetchGamesFailure, handleGameSearch} =
  gameSlice.actions;
export default gameSlice.reducer;

//export const selectGames = (state: RootState) => state.games.currentGames;
