import React, { useEffect } from 'react'
import Slider from './Slider'
import { GameSection } from './GameSection'
import { Footer } from './Footer'

export const MainPage: React.FC<any> = ({ games, setIsSearchBarVisible, isSearching }) => {
    
    useEffect(() => {
        // window.onscroll = function () { myFunction() };

        // var header: any = document.getElementById("myHeader");
        // var sticky = header.offsetTop;

        // function myFunction() {
        //     if (window.pageYOffset > sticky) {
        //         header.classList.add("sticky");
        //     } else {
        //         header.classList.remove("sticky");
        //     }
        // }
    }, [])
    return (
        <div className="flex-1 text-white flex-grow md:mt-[10%] md:ml-20 md:mr-20" >

            <Slider />

            <GameSection data={games} setIsSearchBarVisible={setIsSearchBarVisible} isSearching={isSearching}/>

            {/* <Footer /> */}

        </div>
    )
}
