import React from 'react'
import NotoBall from '../asset/noto-ball.png';

export const AirDropBonusInfo = () => {
    return (
        <div className="mt-0 bg-[#0A0A0A] p-3 border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
           
                <div className="flex items-center mb-3">
                    <img src={NotoBall} alt="Icon" className="w-16 h-16 md:w-18 md:h-18 mr-1 -mt-[1.8rem]" />
                    <p className="text-[0.6rem] md:text-[0.8rem] text-[#D9D9D9] mt-8">Earn airdrop points and bonuses by
                        referring people to Betracle </p>
                </div>
         

            <p className="text-right text-[0.5rem] md:text-[0.5rem] text-[#838383]">Terms And Conditions Apply</p>
        </div>
    )
}
