import React from 'react'

export const PleaseWait: React.FC<any> = ({ text }) => {
    return (
        <div className="bg-transparent glass-card border-[1px] border-[#2A2A2A] border-radius shadow-lg p-6 max-w-xs mx-auto" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
            <div className="text-center">
                {/* <svg className="animate-spin h-6 w-6 md:h-12 md:w-12 mx-auto text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.5A9.5 9.5 0 002.5 12H4zm2 8a9.956 9.956 0 01-2-6.5H.5A11.48 11.48 0 0012 21.5V20zm16-6a8 8 0 01-8 8v2.5a9.5 9.5 0 009.5-9.5H20z"></path>
                </svg> */}
                <h2 className="mt-4 text-[0.8rem] md:text-[1.1rem] font-semibold  text-[#D9D9D9] font-[900] font-[Orbitron]">{text}</h2>
            </div>
        </div>
    )
}
