import React from 'react'
import { motion } from 'framer-motion';

export const RewardCard = () => {
    return (
        <div>
            {/* prediction point */}
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}

            >
                <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-[#0B0B0B] glass-card w-[20rem] flex justify-between items-center p-5 mb-5' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                    <p className="text-[2rem] text-[#F6A823] font-[900] font-[Orbitron]">5</p>

                    <div>
                        <h1 className="text-[0.8rem] text-[#D9D9D9] font-[200]">prediction</h1>
                        <h1 className="text-[0.8rem] text-[#D9D9D9] font-[200]">Point</h1>
                    </div>

                    <button className="bg-[#00170C] text-[#00FF87] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                        claim
                    </button>
                </div>
            </motion.div>

            {/*airdrop point */}
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1.5 }}

            >
                <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-[#0B0B0B] glass-card w-[20rem] flex justify-between items-center p-5 mb-10' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                    <p className="text-[2rem] text-[#00FF87] font-[900] font-[Orbitron]">1</p>

                    <div>
                        <h1 className="text-[0.8rem] text-[#D9D9D9] font-[200]">prediction</h1>
                        <h1 className="text-[0.8rem] text-[#D9D9D9] font-[200]">Point</h1>
                    </div>

                    <button className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                        claim
                    </button>
                </div>
            </motion.div>


            {/* information */}
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2 }}

            >
                <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg bg-gradient-to-r from-[#6D00F7] to-[#000691] glass-card w-[20rem] flex justify-between items-center p-5 space-x-2' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                    <p className="text-[0.6rem] md:text-[0.9rem] text-[#D9D9D9] ">join ongoing airdrop
                        and earn airdrop
                        points.</p>


 
                    <button className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                        join
                    </button>
                </div>
            </motion.div>

        </div>
    )
}
