import React, { useState } from 'react'
import { motion } from 'framer-motion';

export const Step1: React.FC<any> = ({ onNextStep, onSkipSteps }) => {

    return (
        <div>
            

            <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

                <div className="modal-overlay absolute top-[64px] w-full h-full bg-[#000000] opacity-50">
                    <div className='absolute bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card right-[60%] md:right-[17%] -top-8 z-10' style={{ borderRadius: '50%', boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                        <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>1</h1>
                    </div>
                </div>
                
                <div className="modal-container top-[100px] right-10 w-[72%] md:w-[25%]  absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
                >
                    
                    <div className="modal-content py-4 text-left px-6">
                        {/* <div className='relative bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card' style={{ borderRadius: '50%'}}>
                            <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>1</h1>
                        </div> */}

                        <h1 className='text-right text-[#545454] mb-10 text-[0.6rem] md:text-[0.7rem]'>1/5</h1>

                        <div className='mb-10'>
                            <h1 className='text-[#00FF87] font-extrabold font-[Orbitron] mb-2 text-[0.7rem] md:text-[0.9rem]'>Welcome to Betracle AI</h1>
                            <p className='text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                                this is your profile menu, it gives you access
                                to different options related to your account and
                                other important features.
                            </p>
                        </div>

                        <div className='flex items-center justify-between mb-3'>
                            <button onClick={onSkipSteps} className="bg-transparent text-[#00FF87] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                                Skip
                            </button>

                            <button onClick={onNextStep} className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}



