import React, { useState } from 'react';
import AccountAdd from '../../../asset/account-add.png';
import Logout from '../../../asset/logout-circle.png';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ForgotPasswordApi } from '../../../Redux/apiCalls';
import Loader from '../../../Utils/Loader/Loader';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ForgotPasswordFeature: React.FC<any> = ({ setActiveComponent }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSendRequest = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if(email === '') {
            toast.warn("Email field is required.");
            setIsLoading(false);
        }
        else {
            try {
                const _tmp = await ForgotPasswordApi(email);
                if(_tmp.data.message === 'Email sent with password reset instructions') {
                    MySwal.fire({
                        title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Email sent</p>,
                        text: "Email sent with password reset instructions",
                        icon: "success",
                        allowOutsideClick: false,
                      }).then((result: any) => {
                        if (result.isConfirmed) {
                            setActiveComponent('signIn');
                        }
                    });
                    // toast.success(_tmp.data.message);
                    setIsLoading(false);
                }
                else {
                toast.warn(_tmp.data.message);
                setIsLoading(false);
                }
            } catch (error: any) {
                toast.warn(error.message);
                setIsLoading(false);
            }
        }
    }
    return (
        <div className="flex justify-center items-center min-h-screen p-2 md:p-0">
            <ToastContainer autoClose={5000} />
            {isLoading && <Loader />}
            <div className=" p-8 border-radius shadow-lg w-full md:w-96 glass-card">
                <h1 className="text-[1.2rem] md:text-2xl font-semibold mb-4 text-[#8D8D8D] py-8 font-medium">Forgot Password</h1>
                <form>
                    <div className="mb-4">

                        <input
                            type="email"
                            className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.7rem] md:text-[1rem]"
                            placeholder='Enter your email'
                            style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="my-8 md:my-16">
                        <button
                            type="submit"
                            className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[0.8rem] md:text-[1rem] py-4 px-4 w-full rounded"
                            onClick={handleSendRequest}
                            disabled={isLoading}
                        >
                            
                            {isLoading ? 'Processing...' : 'Send request'}
                        </button>
                    </div>


                    <div className="flex items-center justify-between mt-8">
                        {/* Left-hand side */}
                        <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('signIn')}>
                            <img src={Logout} alt="Left Image" className="w-6 h-6 mr-2" />
                            <p className="text-[#8D8D8D] text-sm font-medium">Log In</p>
                        </div>

                        {/* Right-hand side */}
                        <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('signUp')}>
                            <img src={AccountAdd} alt="Right Image" className="w-6 h-6" />
                            <p className="text-[#8D8D8D] text-sm font-medium ml-2">Sign Up</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordFeature;
