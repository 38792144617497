import React, { useState } from 'react'
import { AirDropHeader } from './Components/Header'
import { AirDropSideBar } from './Components/AirDropSideBar'
import { Tasks } from './Components/Tasks'
import { AirDropMenu } from './Components/AirDropMenu'
import GoogleFormViewer from '../../Components/GoogleSurvey'

export const AirDropIndexPage = ({ }) => {
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {

        setShowSidebar(!showSidebar);
    };

    return (
        <div className="w-full h-screen">
            {!showSidebar && <AirDropHeader toggleSidebar={toggleSidebar}/>}
            { showSidebar && <AirDropMenu  toggleSidebar={toggleSidebar}/>}


            {!showSidebar && <div className="flex">
                
                <div className="flex-1 border-[1px] border-[#2A2A2A] border-radius p-5 m-4">
                    <Tasks />

                    {/* <GoogleFormViewer /> */}
                </div>

                <AirDropSideBar />
            </div>}
        </div>
    )
}
