import React, { useEffect, useState } from 'react'

export const AirDropCountDown = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-06-03') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="mt-0 ">
            {/* New Section */}
            <div className="mt-0 bg-[#000000] p-5 border-[1px] border-[#2A2A2A] border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
                <div className="text-left">
                    <div className="flex items-center mb-3">

                        <p className="text-[0.7rem] md:text-[0.8rem] text-[#545454] font-[200]">Airdrop Ends on 3rd jun 2024</p>
                    </div>
                    <div className="grid grid-cols-4 gap-2">
                        <div>
                            <p className="text-lg font-bold text-[#00FF87] text-2xl md:text-4xl font-extrabold font-[Orbitron] text-center">{timeLeft?.days}</p>
                            <p className="text-[#00FF87] text-[0.6rem] md:text-[0.7rem] text-center">Days</p>
                        </div>
                        <div>
                            <p className="text-lg font-bold text-[#00FF87] text-2xl md:text-4xl font-extrabold font-[Orbitron] text-center">{timeLeft.hours}</p>
                            <p className="text-[#00FF87] text-[0.6rem] md:text-[0.7rem] text-center">Hours</p>
                        </div>
                        <div>
                            <p className="text-lg font-bold text-[#00FF87] text-2xl md:text-4xl font-extrabold font-[Orbitron] text-center">{timeLeft.minutes}</p>
                            <p className="text-[#00FF87] text-[0.6rem] md:text-[0.7rem] text-center">Minutes</p>
                        </div>
                        <div>
                            <p className="text-lg font-bold text-[#00FF87] text-2xl md:text-4xl font-extrabold font-[Orbitron] text-center">{timeLeft.seconds}</p>
                            <p className="text-[#00FF87] text-[0.6rem] md:text-[0.7rem] text-center">Seconds</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
