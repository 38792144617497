import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userRedux";
import gamesReducer from "./gamesRedux";
import marketsReducer from "./marketRedux";
import userRegistrationReducer from './registerRedux';

import {
    persistStore,
    persistReducer, 
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const marketPersistConfig = {
    key: "market",
    version: 1,
    storage,
};

const gamePersistConfig = {
    key: "game",
    version: 1,
    storage,
};

//const rootReducer = combineReducers({ user: userReducer, cart: cartReducer });

const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedMarketReducer = persistReducer(marketPersistConfig, marketsReducer);
const persistedGameReducer = persistReducer(gamePersistConfig, gamesReducer);



export const store = configureStore({
    //reducer: persistedReducer,
    reducer: {
        user: persistedReducer,
        market: persistedMarketReducer,
        game: persistedGameReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export let persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>