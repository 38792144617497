import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logout from '../../../asset/logout-circle.png';
import { AddReferral, Register, SendVerificationEmail, login } from '../../../Redux/apiCalls';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../../../Utils/Loader/Loader';
import EyeOpen from '../../../asset/eye.svg';
import EyeSlash from '../../../asset/eye-off.svg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const SignUpFeature: React.FC<any> = ({ setActiveComponent, referral }) => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const dispatch = useDispatch();

    const cUser = useSelector((state: any) => state.user.currentUser);

    const handleSignUp = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            if (userName === '' || email === '' || password === '') {
                toast.warn("All fields are required.");
                setIsLoading(false);
            }
            else {
                let data = {
                    username: userName,
                    email: email,
                    password: password,
                    referralCode: (referral !== undefined) ? referral : null
                };

                const user = await Register(data);
                if (user.status === 201) {
                    if (referral !== undefined) {

                        let userId = user.data.user.id
                        let referralData = {
                            username: referral,
                            referralTypeId: userId
                        }

                        const info = await AddReferral(referralData);

                        if (info.message === 'Successful') {
                            //toast.success("Registration successful");
                            const verificationInfo = await SendVerificationEmail(email)
                            if (verificationInfo.message === 'Verification email sent') {
                                setIsLoading(false);
                                MySwal.fire({
                                    title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Email sent</p>,
                                    text: "Verification email sent. Please check your email",
                                    icon: "success",
                                    allowOutsideClick: false,
                                }).then((result: any) => {
                                    if (result.isConfirmed) {
                                        setActiveComponent('signIn');
                                    }
                                });

                            }
                            else {
                                toast.warn("An error has occurred. please try again")
                            }
                            return;
                            setTimeout(async () => {
                                await login(dispatch, email, password);
                                setIsLoading(false);
                            }, 3000);
                        }
                        else {
                            setIsLoading(false);
                            toast.warn("Please try again.");
                        }

                    }
                    else {
                        const verificationInfo = await SendVerificationEmail(email)
                        if (verificationInfo.message === 'Verification email sent') {
                            setIsLoading(false);
                            MySwal.fire({
                                title: <p className='text-[1rem] md:text-[1.8rem] font-regular text-[#000000]'>Email sent</p>,
                                text: "Verification email sent. Please check your email",
                                icon: "success",
                                allowOutsideClick: false,
                            }).then((result: any) => {
                                if (result.isConfirmed) {
                                    setActiveComponent('signIn');
                                }
                            });
                        }
                        else {
                            toast.warn("An error has occurred. please try again")
                        }
                        return
                        toast.success("Registration successful");
                        setTimeout(async () => {
                            await login(dispatch, email, password);
                            setIsLoading(false);
                        }, 3000);
                    }
                }
                else {
                    setIsLoading(false);
                    toast.warn(user.data.message);
                }


                return;

                if (referral !== undefined) {

                    const user = await Register(data);
                    if (user.status === 201) {

                        // add referral
                        let userId = user.data.user.id

                        let referralData = {
                            username: referral,
                            referralTypeId: userId
                        }

                        // send data to endpoint
                        await AddReferral(referralData);

                        toast.success("Registration successful");
                        setTimeout(async () => {
                            await login(dispatch, email, password);
                            setIsLoading(false);
                        }, 3000);
                    }
                }
                else {

                    const user = await Register(data);
                    if (user.status === 201) {
                        toast.success("Registration successful");
                        // setActiveComponent('signIn');
                        setTimeout(async () => {
                            await login(dispatch, email, password);
                            setIsLoading(false);
                        }, 3000);
                    }
                    else {
                        setIsLoading(false);
                        toast.warn(user.data.message);
                    }
                }
            }

        } catch (error: any) {
            console.log(error);
            toast.warn(error.message);
            setIsLoading(false);
        }
    }
    return (

        <div className="flex justify-center items-center min-h-screen p-2 md:p-0">
            <ToastContainer autoClose={5000} />
            {isLoading && <Loader />}
            <div className=" p-8 border-radius shadow-lg w-full md:w-96 glass-card">
                <h1 className="text-[1.2rem] md:text-2xl font-semibold mb-4 text-[#8D8D8D] py-8 font-medium">Sign Up</h1>
                <form>
                    <div className="mb-4">

                        <input
                            type="text"
                            className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.7rem] md:text-[1rem]"
                            placeholder='Username'
                            style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">

                        <input
                            type="email"
                            className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.7rem] md:text-[1rem]"
                            placeholder='Email'
                            style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4 relative">

                        <input
                            type={showPassword ? 'text' : 'password'}
                            className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-radius placeholder-[#545454] border text-[0.7rem] md:text-[1rem]"
                            placeholder='Password'
                            style={{ background: 'rgb(51 51 51 / 79%)', borderColor: 'rgb(255 255 255 / 0.1)' }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            className="absolute inset-y-0 right-0 px-3 py-2"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ?
                                <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                :
                                <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                            }
                        </button>
                    </div>
                    <div className="my-8 md:my-16">
                        <button
                            type="submit"
                            className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[0.8rem] md:text-[1rem] py-4 px-4 w-full border-radius"
                            onClick={handleSignUp}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Creating account' : 'Sign Up'}
                        </button>
                    </div>


                    <div className="flex items-center justify-between mt-8">
                        {/* Left-hand side */}
                        <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('signIn')}>
                            <img src={Logout} alt="Left Image" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                            <p className="text-[#8D8D8D] text-[0.8rem] md:text-sm  font-medium">Log In</p>
                        </div>

                        {/* Right-hand side */}
                        <div className="flex items-center">
                            {/* <img src={AccountAdd} alt="Right Image" className="w-6 h-6" />
                            <p className="text-[#8D8D8D] text-sm font-medium ml-2">Sign Up</p> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUpFeature;
