
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutPage from "./Screens/LayoutPage";
import AuthenticationPage from "./Screens/Authentication/AuthPage";
import ResetPasswordFeature from "./Screens/Authentication/Components/ResetPassword";
import LoadingData from "./Utils/LoadingData";
import VerifyEmailPage from "./Screens/Authentication/Components/VerifyEmailPage";
import PrivateRoutes from "./Utils/privateRoutes";
import { AirDropIndexPage } from "./Screens/AirDrops/Index";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={ <AuthenticationPage />} />
        <Route path='/signup/ref/:username' element={<AuthenticationPage />} />
        <Route path="/resetPassword/:token" element={<ResetPasswordFeature />} />
        <Route path="/confirmation/:email/:token" element={<VerifyEmailPage />} />

        <Route path="/loading-data" element={ <LoadingData />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<LayoutPage />} />
          <Route path="/airdrop" element={<AirDropIndexPage />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
}

export default App;
