import React, { useEffect, useState } from 'react';
// import PredictionModal from './Modals/PredictionModal';
// import OutOfPointModal from './Modals/OutOfPointModal';
// import WinDrawLose from './Pages/Dashboard/PredictionResults/win_draw_lose';
// import CorrectScore from './Pages/Dashboard/PredictionResults/CorrectScore';
// import DoubleChance from './Pages/Dashboard/PredictionResults/DoubleChance';
// import GoalGoal from './Pages/Dashboard/PredictionResults/GoalGoal';
// import HalftimeFulltime from './Pages/Dashboard/PredictionResults/Halftime_Fulltime';
// import OddEven from './Pages/Dashboard/PredictionResults/OddEven';
// import OverUnder from './Pages/Dashboard/PredictionResults/OverUnder';
// import { useDispatch, useSelector } from 'react-redux';
// import { DecreasePredictionPoint, NewHistory } from '../Redux/apiCalls';
// import { Utils } from '../Utils/utils';
// import Spinner from '../Utils/Spinner';
import SolidTime from '../asset/lock-fill.png';
import WinDrawLose from './PredictionResults/WinDrawLose';
import CorrectScore from './PredictionResults/CorrectScore';
import DoubleChance from './PredictionResults/DoubleChance';
import GoalGoal from './PredictionResults/GoalNoGoal';
import OddEven from './PredictionResults/OddEven';
import OverUnder from './PredictionResults/OverUnder';
import HalftimeFulltime from './PredictionResults/HalfTimeFullTime';
import { DecreasePredictionPoint, GetAllMarkets, NewHistory } from '../Redux/apiCalls';
import OverUnder1_5 from './PredictionResults/OverUnder1_5';
import OverUnder3_5 from './PredictionResults/OverUnder3_5';
import { handleDecreasePredictionPoint } from '../Redux/userRedux';
import { useDispatch, useSelector } from 'react-redux';
import PredictionModal from './Modals/PredictionModal';
import OutOfPointModal from './Modals/OutOfPointModal';
// import { handleDecreasePredictionPoint } from '../Redux/userRedux';
// import NoPrediction from './Pages/Dashboard/PredictionResults/NoPrediction';

// const tabss = [
//   { value: '1X2', market_name: '1X2', isActive: true },
//   { value: 'Correct Score', market_name: 'Correct Score', isActive: true },
//   { value: 'Double Chance', market_name: 'Double Chance', isActive: true }, // Locked tab
//   { value: 'Goal No Goal', market_name: 'Goal No Goal', isActive: true }, // Locked tab
//   { value: 'Halftime/Fulltime', market_name: 'Halftime/Fulltime', isActive: true }, // Locked tab
//   { value: 'Odd/Even', market_name: 'Odd/Even', isActive: true },
//   { value: 'Over/Under 2.5', market_name: 'Over/Under 2.5', isActive: true },
// ];


const Markets: React.FC<any> = ({ tabs, game }) => {

  const [activeSelection, setActiveSelection] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [predictionContainer, setPredictionContainer] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);
  const consent = useSelector((state: any) => state.user.consent);
  const point = useSelector((state: any) => state.user.predictionPoint);

  const dispatch = useDispatch();


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConsentModal = () => {
    setIsConsentModalOpen(true);
  };

  const closeConsentModal = () => {
    setIsConsentModalOpen(false);
  };

  const handleTabClick = (value: any) => {


    setActiveSelection(value.market_name);
    setPredictionContainer(true);
    DecreaseUserPredictionPoint(value);
  };


  useEffect(() => {

    // const USER:any = localStorage.getItem("USER_DETAILS");
    // let parsedUser = JSON.parse(USER);
    // console.log(Utils.PredictionPoint)

  }, [])


  const DecreaseUserPredictionPoint = async (value: any) => {


    try {
      setIsSpinner(true);
      if (point > 0) {
        if (game.prediction !== null && game.prediction?.win_draw !== '' && game.prediction?.win_draw !== null) {
          let _tmp = {
            gameId: game.id,
            marketId: value.id,

          }
          const info = await DecreasePredictionPoint(user.user.id, _tmp);
          if (info.message === 'Successful') {

            dispatch(handleDecreasePredictionPoint());
            let predict;

            switch (value.market_name) {
              case "1X2":
                predict = game.prediction.win_draw
                break;
              case "Correct Score":
                predict = game.prediction.correct_score
                break;
              case "Double Chance":
                predict = game.prediction.double_chance
                break;
              case "Goal No Goal":
                predict = game.prediction.goal_no_goal
                break;
              case "Halftime/Fulltime":
                predict = game.prediction.halftime_fulltime
                break;
              case "Odd/Even":
                predict = game.prediction.odd_even
                break;
              case "Over/Under 1.5":
                predict = game.prediction.over_under1_5
                break;
              case "Over/Under 2.5":
                predict = game.prediction.over_under
                break;
              case "Over/Under 3.5":
                predict = game.prediction.over_under3_5
                break;
              default:
                break;
            }

            let payload = {
              userId: user.user.id,
              gameId: game.id,
              marketId: value.id,
              prediction: predict
            }

            const info = await NewHistory(payload);
            console.log(info)
            setIsSpinner(false);
          }
          else {
            setIsSpinner(false);
          }
        }
        else {

          setIsSpinner(false);

        }


        // console.log(info);

      }
      else {
        setIsSpinner(false);
      }

    } catch (error) {
      setIsSpinner(false);
      console.log(error)
    }
  }

  const renderPredictionsComponent = () => {
 
    switch (activeSelection) {
      case '1X2':
        return <WinDrawLose game={game} />;
      case 'Correct Score':
        return <CorrectScore game={game} />;
      case 'Double Chance':
        return <DoubleChance game={game}/>;
      case 'Goal No Goal':
        return <GoalGoal game={game} />;
      case 'Halftime/Fulltime':
        return <HalftimeFulltime game={game}/>;
      case 'Odd/Even':
        return <OddEven game={game} />;
      case 'Over/Under 2.5':
        return <OverUnder game={game} />;
      case 'Over/Under 1.5':
        return <OverUnder1_5 game={game} />;
      case 'Over/Under 3.5':
        return <OverUnder3_5 game={game} />;
      default:
        return null;
    }
  };


  return (
    <>
      <ul className="flex flex-wrap text-sm font-medium text-center  md:w-full">
        {tabs ? tabs?.map((tab: any, index: number) => (
          <li key={index} className={`mb-4 mr-7`}>
            <button
              className={`inline-block flex justify-between px-4 py-2 md:py-3 ${activeSelection === tab.market_name
                ? 'text-[#000000] bg-[#00FF87] font-[900]'
                : 'text-[#545454] bg-[#151515] font-[100]'
                } w-full md:w-[160px] border-radius text-[0.6rem] md:text-[0.7rem]`}
              onClick={() => {
                if (consent === false) {
                  openConsentModal();
                }
                else {
                  if (point === 0) {
                    openModal();
                  }
                  else {
                    handleTabClick(tab);
                  }
                }
                // handleTabClick(tab);
              }}
              style={{ boxShadow: '4px 4px 0 0 #000000', cursor: !tab.isActive ? 'not-allowed' : 'pointer', }}
              disabled={!tab.isActive}
            >
              {tab.market_name}
              {!tab.isActive && <div className="w-4 h-4 md:w-4 md:h-4 text-white ml-2">
                <img src={SolidTime} alt="Icon" className="text-[#00FF87]" />
              </div>}
            </button>
          </li>
        )) : (<div className=''>
          <div className="bg-red-500 text-[#838383] font-[200] py-2 px-4 border-radius shadow-md">
            No prediction available yet
          </div>
        </div>)}
      </ul>
      <PredictionModal openModal={openConsentModal} closeModal={closeConsentModal} isModalOpen={isConsentModalOpen} />
      <OutOfPointModal openModal={openModal} closeModal={closeModal} isModalOpen={isModalOpen} />

      <div className='mb-0'>
        {/* {isSpinner && <Spinner />}
        {
          !isSpinner && renderPredictionsComponent()
        } */}

        <div className='overflow-x-auto no-scrollbar'>
          <div className="flex flex-row">
            {renderPredictionsComponent()}
          </div>
        </div>




      </div>
    </>
  );
}

export default Markets;

